<se-fe-card *ngIf="!ready || (ready && (sortedAccountItems?.length || inviteItems?.length))">
  <se-fe-card-header seFeDataTitle="Permissions" seFeDataHeavyBorder="true" [seFeDataSubtitle]="subtitle">
    <div seFeCardHeaderActions>
      <se-fe-button *ngIf="ready && (useAddParentModal || canManageProfile) && !isSelf"
        (click)="openAddModal()"
        [seFeDataIsDisabled]="disableGrant()"
        [seFeTooltipTrigger]="disableGrant() ? 'parentAddTooltip' : ''"
        [ngClass]="{'disabled': disableGrant()}">
        <se-fe-icon seFeDataName="plus" seFeDataSize="500" seFeDataColor="primary"></se-fe-icon>
        <span style="color: #00668f;"> Add</span>
      </se-fe-button>
      <se-fe-tooltip *ngIf="hasMetGuardianLimit" [seFeDataName]="'parentAddTooltip'">
        {{ profile.persona.first_name }} has reached the limit for guardianship access. You can manage access from the permissions card.
      </se-fe-tooltip>
      <se-fe-tooltip *ngIf="!hasMetGuardianLimit && disableGrant()" [seFeDataName]="'parentAddTooltip'">
        {{ profile.user.self_persona.first_name }} cannot be granted access to any additional members of your household
      </se-fe-tooltip>
    </div>
  </se-fe-card-header>
  <se-fe-card-content *ngIf="!ready">
    <se-fe-spinner
      [seFeDataSize]="900"
      [seFeDataInverted]="false"
      [seFeDataCenter]="true">
    </se-fe-spinner>
  </se-fe-card-content>
  <se-fe-card-content *ngIf="ready">
    <se-fe-list class="guardian-list">
      <se-fe-list-item *ngFor="let accountItem of sortedAccountItems">
        <ng-container *ngTemplateOutlet="guardianListItem; context: { listItem: accountItem }"></ng-container>
      </se-fe-list-item>
      <se-fe-list-item *ngFor="let inviteItem of inviteItems">
        <ng-container *ngTemplateOutlet="guardianListItem; context: { listItem: inviteItem }"></ng-container>
      </se-fe-list-item>
    </se-fe-list>
  </se-fe-card-content>
</se-fe-card>
<se-fe-toast></se-fe-toast>

<ng-template #guardianListItem let-listItem="listItem">
  <se-fe-header [seFeDataTitle]="listItem.title" [seFeDataSubtitle]="(!listItem.pending || isGuardian) && listItem.subtitle" seFeDataSize="600">
    <se-fe-avatar seFeHeaderVisual
      seFeDataType="persona"
      [seFeDataIdentity]="listItem.title"
      [seFeDataImgUrl]="listItem.imgUrl"
      seFeDataSize="300">
    </se-fe-avatar>
    <se-fe-chip *ngIf="!(listItem.pending || listItem.accessOptions)"
      seFeHeaderTitleExtra [seFeDataText]="listItem.access | titlecase"></se-fe-chip>
    <div *ngIf="listItem.pending && isGuardian" seFeHeaderTitleExtra>
      <se-fe-chip seFeDataText="Pending" [seFeMenuTrigger]="listItem.menuOptions?.name">{{ listItem.email }}</se-fe-chip>
      <se-fe-menu *ngIf="listItem.menuOptions" [seFeDataOptions]="listItem.menuOptions"></se-fe-menu>
    </div>
    <div seFeHeaderTitleExtra
      *ngIf="!listItem.pending && listItem.menuOptions && listItem.accessOptions && !(isSelf && listItem.access === 'owner')">
      <se-fe-chip [seFeDataText]="listItem.access | titlecase" [seFeMenuTrigger]="listItem.accessOptions.name"></se-fe-chip>
      <se-fe-menu [seFeDataOptions]="listItem.accessOptions"></se-fe-menu>
    </div>
    <div *ngIf="listItem.pending && !isGuardian" seFeHeaderSubtitle>
      <se-fe-chip seFeDataText="Pending" [seFeMenuTrigger]="listItem.menuOptions?.name">{{ listItem.subtitle }}</se-fe-chip>
      <se-fe-menu *ngIf="listItem.menuOptions" [seFeDataOptions]="listItem.menuOptions"></se-fe-menu>
    </div>
    <se-fe-icon-button seFeHeaderActions *ngIf="!listItem.pending && listItem.menuOptions"
      [seFeMenuTrigger]="listItem.menuOptions.name"
      seFeDataEmphasis="low"
      [seFeDataSnug]="true">
      <se-fe-icon seFeDataName="more" seFeDataSize="700"></se-fe-icon>
    </se-fe-icon-button>
  </se-fe-header>
  <div *ngIf="listItem.menuOptions"><se-fe-menu [seFeDataOptions]="listItem.menuOptions"></se-fe-menu></div>
</ng-template>

<se-fe-modal #removeConfirmModal>
  <se-fe-modal-content *ngIf="guardianToRemove">
    <se-fe-header seFeDataTitle="Remove Access?" [seFeDataLevel]="3" seFeDataSize="500">
    </se-fe-header>
    <div class="modal-item">You are about to remove:</div>
    <div class="modal-item">
      <se-po-persona-avatar
        [headerSubtitle]="guardianToRemove.user?.email_address.address || guardianToRemove.persona.email_addresses[0].address"
        [persona]="guardianToRemove.user?.self_persona">
      </se-po-persona-avatar>
    </div>
    <div class="modal-item">This will revoke all access that {{ guardianToRemove.user?.self_persona.full_name || guardianToRemove.persona?.full_name }} has to {{ accessModifySubject.persona?.first_name || accessModifySubject.user?.self_persona.first_name }}'s profile.</div>
  </se-fe-modal-content>
  <div seFeModalFooter>
    <se-fe-modal-footer
      #removeConfirmFooter
      [seFeDataFooterButtons]="removeConfirmButtons">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
<se-fe-modal #downgradeConfirmModal>
  <se-fe-modal-content *ngIf="accessModifySubject">
    <se-fe-header seFeDataTitle="Are you sure you want to change your access?" [seFeDataLevel]="3" seFeDataSize="500">
    </se-fe-header>
    <div class="modal-item">You are about to change your access from a manager to a viewer for:</div>
    <div class="modal-item">
      <se-po-persona-avatar
        [persona]="accessModifySubject.user?.self_persona || accessModifySubject.persona">
      </se-po-persona-avatar>
    </div>
    <div class="modal-item">
      To change your access back to a manager, you will need to reach out to the account owner for {{ (accessModifySubject.user?.self_persona || accessModifySubject.persona)?.full_name }}.
    </div>
  </se-fe-modal-content>
  <div seFeModalFooter>
    <se-fe-modal-footer
      [seFeDataFooterButtons]="downgradeConfirmButtons">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
<se-po-child-grant-access-modal *ngIf="useAddChildModal" #addChildPermissionsModal [profile]="profile" [guardians]="guardians" [inviteItems]="inviteItems" (guardianAdded)="guardianAdded()"></se-po-child-grant-access-modal>
<se-po-parent-grant-access-modal *ngIf="useAddParentModal" #addParentPermissionsModal [profile]="profile" [profiles]="profiles" [accountItems]="sortedAccountItems" [guardians]="guardians" [allInvites]="allInvites" [inviteItems]="inviteItems" (guardianAdded)="guardianAdded()"></se-po-parent-grant-access-modal>
