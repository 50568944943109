import { Injectable } from '@angular/core'
import { Observable, forkJoin } from 'rxjs'
import { take } from 'rxjs/operators'
import { Team } from '../models'
import { AlertPreferenceService } from './alert-preference.service'

@Injectable({
  providedIn: 'root'
})
export class TeamAlertPreferencesService {

  public hybrid_team_id: string
  public team: Team
  public loaded = false

  constructor(
    private alertPreferenceService: AlertPreferenceService
  ) {
    // noop
  }

  public loadPreferences(team): Observable<any> {
    this.hybrid_team_id = null

    team.extended_attributes.forEach(t => {
      if (t.key === 'ngin_team_id') this.hybrid_team_id = t.value
    })

    if (!this.hybrid_team_id) this.hybrid_team_id = team.id

    const prefs = team.trigger_roster_personas.reduce(
      (prefObj, currentValue) => {
        prefObj[currentValue.persona.persona_id] = this.loadAlertPreferences(currentValue)
        return prefObj
      },
      {}
    )
    return forkJoin(prefs).pipe(take(1))
  }

  public loadAlertPreferences(rpData): Observable<any> {
    return this.alertPreferenceService.get({
      find_or_create: true,
      persona_id: rpData.persona.persona_id,
      context_type: 'Team',
      resource_type: 'PersonaEmailAddress',
      context_id: this.hybrid_team_id
    })
  }
}
