import { Component, OnInit, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { environment as env } from '@se-po/shared-environments'
import { take } from 'rxjs/operators'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { Metadata } from 'se-resource-types/dist/lib/common'
import { Team } from '../models'
import { AlertPreferenceService } from '../services/alert-preference.service'
import { SpinnerService } from '../services/spinner.service'
import { TeamService } from '../services/team.service'
import { TeamsRemindersComponent } from './teams-reminders.component'

@Component({
  selector: 'app-teams-page',
  templateUrl: './teams-page.component.html',
  styleUrls: ['./teams-page.component.scss']
})
export class TeamsPageComponent implements OnInit {
  @ViewChild('modal') public modal: SeFeModalComponent
  @ViewChild(TeamsRemindersComponent) public teamModal: TeamsRemindersComponent
  @ViewChild('modalFooter') public modalFooter: SeFeModalFooterComponent

  public teams: Team[]
  public teamsById: { [key: number]: Team } = {}
  public teamDetails: { [key: number]: string[] } = {}
  public teamExtra: { [key: number]: string } = {}
  public loaded = false
  public modalOpen = false
  public modalTitle = ''
  public options: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'cancel',
      action: () => this.modalClose()
    },
    {
      primary: true,
      label: 'save',
      action: () => this.modalSubmit()
    }
  ]
  public selectedTeam: Team
  public loadingMore = false
  public pageMetadata: Metadata

  constructor(
    private teamService: TeamService,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private window: Window,
    private alertPreferenceService: AlertPreferenceService
  ) {
    // noop
  }

  public ngOnInit(): void {
    this.spinnerService.start()

    this.teamService.findMine().pipe(take(1))
      .subscribe((val: Team[]) => {
        this.parseTeams(val)
      })
  }

  public decorateTeams(team: any): void {
    team.nextPath = env.app + this.setNextPath(team)

    const teamLinks = []

    if (!team.trigger_roster_personas.length) {
      return
    }

    teamLinks.push({
      text: this.translate.instant('MY_TEAMS.manage_reminders'),
      action: () => { this.manageRemindersModal(team) }
    })

    team.actionLinks = {
      minWidth: '150px',
      name: `team-menu-${team.id}`,
      sections: [{ menuItems: teamLinks }]
    }
  }

  public modalClose(): void {
    this.modalOpen = false
    this.modal.close()
  }

  public modalSubmit(): void {
    const requests = []
    this.teamModal.teamPreferences.enabledPrefs.forEach((pref) => {
      if (!pref.enabled) {
        requests.push(this.savePref(pref))
      }

    })
    this.teamModal.teamPreferences.disabledPrefs.forEach((pref) => {
      if (pref.enabled) {
        requests.push(this.savePref(pref))
      }
    })

    this.modalClose()
  }

  public savePref(pref): void {
    this.alertPreferenceService.put(pref).pipe(take(1)).subscribe()
  }

  public personaNames(team): string {
    return team.trigger_roster_personas.map(val => val.nickname).join(', ')
  }

  public back(): void {
    this.window.history.back()
  }

  public loadMore(): void {
    const next = this.pageMetadata?.pagination?.current_page + 1
    if (!next || this.pageMetadata?.pagination?.last_page) return

    this.loadingMore = true
    this.teamService.findMine({ page: next }).pipe(take(1)).subscribe((val: Team[]) => {
      this.parseTeams(val)
    })
  }

  private parseTeams(response: Team[]): void {
    (this.teams ||= []).push(...response)
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.pageMetadata = response['metadata'] as Metadata
    this.teams.forEach(t => {
      const statNginTeam = t.originator_system === 'StatNgin'
      const season = (statNginTeam ? t.program_primary_text : t.program_secondary_text) || ''
      const org = t.org_details?.name || ''
      const independentTeam = org.startsWith('TeamService-independent_team-')
      this.teamsById[t.id] = t
      this.teamDetails[t.id] = [season, this.personaNames(t)].filter(Boolean)
      this.teamExtra[t.id] = org && !independentTeam ? ` - ${org}` : ''
      this.decorateTeams(t)
    })
    this.setLoadStatus()
  }

  private setNextPath(team: Team): string {
    if (team.originator_system === 'Ngin') {
      return `teams/team-instance-redirect/${team.originator_id}`
    }
    return `teams/${team.id}`
  }

  private manageRemindersModal(team: Team): void {
    this.selectedTeam = team
    this.modalOpen = true
    this.modalTitle = this.translate.instant('TEAM_REMINDERS.title', { teamName: team.name })
    this.modal.open(null)
  }

  private setLoadStatus(): void {
    this.loaded = !!this.teams
    this.loadingMore = false
    if (this.loaded) {
      this.spinnerService.stop()
    }
  }
}
